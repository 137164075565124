import React, { useEffect } from "react";
import "./index.scss";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import { resetPassword } from "../../actions/auth";



const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const apiURL2 = process.env.REACT_APP_API_URL2;
  let codeStatus = 'valid'
  let params = new URLSearchParams(window.location.search);
  let code =  params.get("code")

  useEffect(() => {
    if(code) {
      axios
      .post(apiURL2 + `/public/verify-code`, { code: Number(code) })
      .then((response) => {
          if (response.data.success === false) {
            codeStatus = 'expire'
            navigate(`/resent-email?code=${code}`);
        } else {
          codeStatus = 'valid'
          toast.success('Παρακαλώ επαναφέρατε τον κωδικό πρόσβασής σας')
        }
        })
       .catch((error) => {
        if (error) {
          toast.error("Η υπηρεσία δεν είναι διαθέσιμη αυτή τη στιγμή. Παρακαλώ δοκιμάστε ξανά αργότερα.");
        }
      });
    } else {
      toast.error('Κάτι πήγε λάθος');
    }

  },[])
  return (
    <React.Fragment>
      {codeStatus === 'valid' &&
      <Formik
        initialValues={{ confirmPassword: "", password: "" }}
        onSubmit={(values, actions) => {
          console.log("hello", values);
          dispatch(resetPassword(values.password,values.confirmPassword, code, navigate))   .then(() => {
          })
          .catch(() => {
            // setLoading(false);
          });;
        }}
        const
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .required("Απαιτείται")
            .min(5, "Ο κωδικός πρόσβασης είναι πολύ μικρός")
            .max(64, "Ο κωδικός πρόσβασης είναι πολύ μεγάλος")
            .matches(/(?=.*[0-9])/, "Ο κωδικός πρόσβασης πρέπει να περιέχει έναν αριθμό"),
            confirmPassword:Yup.string()
            .oneOf([Yup.ref('password'), null], 'οι κωδικοί πρέπει να ταιριάζουν')
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;

          return (
            <div className="row align-item-center justify-content-center mt-5">
              <div className="col-md-7">
                <div className="authincation-content">
                  <div className="row align-item-center">
                    <div className="col-xl-12">
                      <div className="login-banner-txt">
                        <h4 className="text-center">Επαναφορά Κωδικού</h4>
                      </div>
                      <div className="auth-form">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-6 mx-auto">
                            <a href="/">
                              <img
                                src="assets/images/prrimer-logo.png"
                                className="img-fluid login-logo-center"
                                alt="logo"
                              />
                            </a>
                          </div>
                        </div>

                        <form onSubmit={handleSubmit} autoComplete="off">

                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="fa fa-lock"></i>
                              </span>
                            </div>
                            <input
                              onChange={handleChange}
                              onBlur={handleBlur}
                              id="password"
                              value={values.password}
                              type="Password"
                              name="password"
                              className={`form-control ${
                                errors.password && touched.password && "error"
                              }`}
                              placeholder="Νέος Κωδικός"
                            />
                          </div>
                          {errors.password && touched.password && (
                            <div className="input-feedback">
                              {errors.password}
                            </div>
                          )}
                             <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                              <i className="fa fa-lock"></i>
                              </span>
                            </div>
                            <input
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.confirmPassword}
                              id="confirmPassword"
                              type="Password"
                              name="confirmPassword"
                              className={`form-control ${
                                errors.confirmPassword && touched.confirmPassword && "error"
                              }`}
                              placeholder="Επιβεβαιώστε τον καινούριο σας κωδικό"
                            />
                          </div>
                          {errors.confirmPassword && touched.confirmPassword && (
                            <div className="input-feedback">{errors.confirmPassword}</div>
                          )}
                          <div className="float-right mt-5">
                            <button
                             // disabled={isSubmitting}
                              type="submit"
                              className="btn btn-primary btn-block float-left mb-5 pt-3"
                            >
                              Αποθήκευση
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
}
    </React.Fragment>
  );
};

export default ResetPassword;

import React from "react";
import chroma from "chroma-js";
import Select from "react-select";
import './dropdown.scss';

const colorStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isSelected, isDisabled, isFocused }) => {
    const color = chroma('#76AEB7');
    return {
      ...styles,
      backgroundColor: isDisabled ? null : isSelected ? "#76AEB7" : isFocused ? "#fff" : null,
      color: isDisabled ? "#ccc" : isSelected ? "#fff" ? "white" : "black" : "#76AEB7",
      cursor: isDisabled ? "not-allowed" : "default",
      ":hover": {
        color: "white",
        backgroundColor: "#76AEB7",
      },
      ":active": {
        ...styles[":active"],
        backgroundColor:
          !isDisabled && (isSelected ? "#76AEB7" : color.alpha(0.3).css()),
      },
      valueContainer: (base) => ({...base, height: '34px'})
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "#fff",
    backgroundColor: "#76AEB7",
    borderRadius: 0,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "#fff",
    backgroundColor: "#76AEB7",
    borderRadius: 0,
    ":hover": {
      backgroundColor: "#49868f",
      color: "white",
    },
  }),
};

const Dropdown = (props) => {
  let classes = props.classes ? props.classes : props.className ? props.className : 'mb-3 w-100';
  let defaultValue = props.defaultValue ? props.defaultValue : null;

  if((defaultValue !== null) && (typeof defaultValue === 'object')) {
    defaultValue.color = '#76AEB7';
  }
  return (
    <React.Fragment>
      <div className={classes}>
        { props.label &&
          <label htmlFor={props.id}>
            {props.label}
            {props.required && <span className="text-danger"> *</span>}
          </label>
        }

        {
          props.enabledValue ?
          <Select
            onClick = {props.onClick}
            name = {props.name ? props.name : ''}
            closeMenuOnSelect={true}
            value={props.value ? props.value : defaultValue}
            defaultValue={ defaultValue }
            isMulti={props.multiSelect}
            options={props.options}
            styles={colorStyles}
            isSearchable={props.searchEnable}
            id={props.id}
            height="5px"
            onChange={props.onChange}
            isDisabled={props.disabled}
            menuPlacement={props.position ? props.position : 'bottom'}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#76AEB7',
                primary: '#76AEB7',
              },
            })}
          />
        :
          <Select
            onClick = {props.onClick}
            menuPlacement={props.position ? props.position : 'bottom'}
            name = {props.name ? props.name : ''}
            closeMenuOnSelect={true}
            defaultValue={ defaultValue }
            isMulti={props.multiSelect}
            options={props.options}
            height="5px"
            styles={colorStyles}
            isSearchable={props.searchEnable}
            id={props.id}
            onChange={props.onChange}
            isDisabled={props.disabled}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#76AEB7',
                primary: '#76AEB7',
              },
            })}
          />
        }
      </div>
    </React.Fragment>
  );
};

export default Dropdown;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { forgotPassword } from "../../../_apis/api";
// import { useHistory, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgotPasssword } from "../../actions/auth";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";

// import { login, forgotUser } from "../../../_reducers/Route";
const ForgotPassword = () => {
  // const history = useHistory();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
  <React.Fragment>
  <Formik
  initialValues={{ email: ""}}
  onSubmit={(values) => {
    dispatch(
      forgotPasssword(
        values.email,
        navigate
      )
    )
      .then(() => {
      })
      .catch(() => {
        // setLoading(false);
      });

    // console.log("hello");
    // userSignin(values, history, dispatch, actions);
  }}
  const
  validationSchema={Yup.object().shape({
    email: Yup.string().required("Απαιτείται email/όνομα χρήστη"),
  })}
>
  {(props) => {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
    } = props;

    return (
    <div>
          <div className="row justify-content-center align-items-center mt-5">
            <div className="col-md-6">
              <div className="authincation-content">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="login-banner-txt">
                      <h4 className="text-center">Ξεχάσατε τον κωδικό</h4>
                    </div>
                    <div className="auth-form">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 mx-auto">
                          <a href="/">
                            <img
                              src="assets/images/prrimer-logo.png"
                              className="img-fluid login-logo-center"
                              alt="logo"
                            />
                          </a>
                        </div>
                      </div>
                      <form onSubmit={handleSubmit} autoComplete="off">
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="fa fa-user"></i>
                              </span>
                            </div>
                            <input
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              id="email"
                              type="text"
                              name="email"
                              className={`form-control ${
                                errors.email && touched.email && "error"
                              }`}
                              placeholder="Email εγγραφής ή Όνομα χρήστη"
                            />
                          </div>
                          {errors.email && touched.email && (
                            <div className="input-feedback">{errors.email}</div>
                          )}
                        <div className="form-row justify-content-center mt-5">

                          <div>
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                          >
                            Επαναφορά Κωδικού
                          </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
     );
    }}
  </Formik>
  </React.Fragment>
  );
};

export default ForgotPassword;


export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const FORGOTPASSWORD_SUCCESS = "FORGOTPASSWORD_SUCCESS";
export const LICENSEKEY_SUCCESS = "LICENSEKEY_SUCCESS";
export const FORGOTPASSWORD_FAIL = "FORGOTPASSWORD_FAIL";
export const LICENSEKEY_FAIL = "LICENSEKEY_FAIL";
export const RESETPASSWORD_SUCCESS = "RESETPASSWORD_SUCCESS";
export const RESETPASSWORD_FAIL = "RESETPASSWORD_FAIL";

import React from "react";
import {Popconfirm} from "antd";
import Dropdown from "../Dropdown";
import {getDefaultOptionByValue, getKeyByName} from '../../../helpers/commonFunctions';
const TableBodyCell = (props) => {
  const {quickEditFields, rowIndex, checkedRows, tableName, columns, data, rowData, cell, dataKey, placeholder, newRow, updateRowData, disabled, isInsertRow, enableDynamicOptions, dynamicOptions, reqData, reqName, showErrors } = props;
  let date = new Date();

  const handleActionClick = (e, type, data, cell, dataKey) => {
    cell.inputType.callbackFn(dataKey, type)
  }

  const handleOnChange = (e, field, defaultValue, ftype = 'default') => {
    // Allow insert only with enter
    if (e.key !== "Enter") {
      if (typeof(newRow) !== 'undefined') {
        newRow(e, field, defaultValue, ftype);
      } else {
        if (rowData) {
          const id = rowData.id ? rowData.id : rowData._id;
          updateRowData && updateRowData(e, field, id);
        }
      }
    } else {
      if (typeof(newRow) !== 'undefined') {
        newRow(e, field, defaultValue, ftype);
      }
    }
  }

  const handleOnKeyDown = (e, field, defaultValue, ftype = 'default') => {
    if (e.key === "Enter") {
      handleOnChange(e, field, defaultValue, ftype);

      // Select Next Input on Enter
      const form = e.target.form;
      if (form) {
        const index = Array.prototype.indexOf.call(form, e.target);
        if (index >= 0) {
          form.elements[index + 1].focus();
        }
      }
    }
  }

  const selectRow = (e, type, rowkey) => {
    cell.inputType.select(e, type, rowkey);
  }

  const CellFormFields = ({cell, rowId, rowData, inputDisabled}) => {
    let required  = showErrors;
    if (tableName && (
        (tableName === "Company New Installations" || tableName === "Edit Company New Installations"))) {
      if (!isInsertRow && (dataKey <= 0 || rowIndex <= 0) && (rowData?.installationMasterId === "0" || rowData?.installationMasterId === 0)) {
        var disabled = true;
      }
      if (!isInsertRow && (dataKey <= 0 || rowIndex <= 0)) {
        required = false;
      }
    }

    if ((cell.field !== 'actions') && (cell.field !== 'select_checkbox')) {
      if (cell.editable) {
        switch(cell.inputType?.type) {
          case 'select':
            if (enableDynamicOptions && enableDynamicOptions === 'true') {
              return <span>
              <select disabled={disabled} {...cell.inputType.config} defaultValue={data} onChange={(e) => handleOnChange(e, cell.field, data)} className="tdelement custom-select">
                <option value="">Select...</option>
                {
                    dynamicOptions.length > 0 && dynamicOptions.map((op, idx) => {
                      return <option key={ idx } value={ op.value }>{ op.label }</option>
                    })
                }
              </select>
                {required && data === '' && <small className="text-danger">Το πεδίο {cell.name} είναι απαραίτητο.</small>}
            </span>
            } else {
              return <span>
                <select disabled={disabled} {...cell.inputType.config} defaultValue={data} onChange={(e) => handleOnChange(e, cell.field, data)} className="tdelement custom-select">
                <option value="">Select...</option>
                  {
                      cell.inputType.options.length > 0 && cell.inputType.options.map((op, idx) => {
                        return <option key={ idx } value={ op.value }>{ op.label }</option>
                      })
                  }
              </select>
                {required && data === '' && <small className="text-danger">Το πεδίο είναι απαραίτητο.</small>}
            </span>
            }
          case 'dropdown':
            let dddefaultData = data;

            if (data === 'true') {
              dddefaultData = true;
            }

            if (data === 'false') {
              dddefaultData = false;
            }
            if(tableName === "Company New Installations" && (cell.field === "doy" || cell.field === "type")){
              return <span>
              <Dropdown
                  {...cell.inputType.config}
                  disabled={disabled}
                  classes={'table-dropdown'}
                  searchEnable={cell.field === "doy"}
                  position={"top"}
                  defaultValue={getDefaultOptionByValue(cell.inputType.options, dddefaultData, 'label' ,'label', 'value')}
                  options={cell.inputType.options}
                  onChange={(e) => handleOnChange(e, cell.field, data, 'dd')}
              />
                {required && (data === '' || data === 0) && <small className="text-danger">Το πεδίο είναι απαραίτητο.</small>}
          </span>
            } else {
              return <span>
              <Dropdown
                  {...cell.inputType.config}
                  disabled={disabled}
                  classes={'table-dropdown'}
                  defaultValue={getDefaultOptionByValue(cell.inputType.options, dddefaultData, 'value' ,'label', 'value')}
                  options={cell.inputType.options}
                  onChange={(e) => handleOnChange(e, cell.field, data, 'dd')}
              />
                {required && (data === '' || data === 0) && <small className="text-danger">Το πεδίο είναι απαραίτητο.</small>}
          </span>
            }
          case 'multiselectdropdown':
            return <span><Dropdown
                {...cell.inputType.config}
                classes={'table-dropdown'}
                multiSelect
                defaultValue={getDefaultOptionByValue(cell.inputType.options, data, 'value' ,'label', 'value')}
                options={cell.inputType.options}
                onChange={(e) => handleOnChange(e, cell.field, data, 'dd')}
            />
              {required && data === '' && <small className="text-danger">Required.</small>}
          </span>
          case 'number':
            return <input type="number" {...cell.inputType.config} defaultValue={ data } onBlur={(e) => handleOnChange(e, cell.field, data)} onKeyDown={(e) => handleOnKeyDown(e, cell.field, data)} className="tdelement form-control" />
          case 'radio':

            let name = cell.field;

            if (cell.inputType.radioLogic && (cell.inputType.radioLogic === 'oneperrow')) {
              name = `${rowId}_${cell.inputType.name}`;
            }

            if (cell.inputType.radioLogic && (cell.inputType.radioLogic === 'onepertable')) {
              name = `${cell.inputType.name}`;
            }

            if (tableName) {
              let key = getKeyByName(tableName);
              name = key + name;
            }

            let isChecked = (data === 'true' || data === 'on') ? true : false;
            return <input disabled={disabled} name={name} checked={isChecked} {...cell.inputType.config} type="radio" onChange={(e) => handleOnChange(e, cell.field, data)} className="tdelement" />
          case 'checkbox':
            let isChecked2 = (data === 'true');
            return <input value={!isChecked2 ? 'true' : 'false'} checked={isChecked2} {...cell.inputType.config} type="checkbox" onChange={(e) => handleOnChange(e, cell.field, data)} className="tdelement custom-checkbox" />
          default:
              return <span>
                <input disabled={disabled} required={required} type="text" {...cell.inputType.config} placeholder={placeholder} defaultValue={ data } onBlur={(e) => handleOnChange(e, cell.field, data)} onKeyDown={(e) => handleOnKeyDown(e, cell.field, data)} className="tdelement form-control" />
                {required && data === '' && <small className="text-danger">Το πεδίο είναι απαραίτητο.</small>}
              </span>
        }
      } else {
          return <span className="tdelement">{ data }</span>;
      }
    } else if(cell.field === 'select_checkbox') {
      let checkIndex = checkedRows?.indexOf(rowIndex);
      if (checkIndex > -1) {
        return <input type="checkbox" defaultChecked={true} onClick={(e) => selectRow(e, 'selectRow', rowIndex)} />
      } else {
        return <input type="checkbox" onClick={(e) => selectRow(e, 'selectRow', rowIndex)} />
      }
    } else {
      if (cell.actionButtons && !(tableName === "Company New Installations" && (dataKey <= 0 || rowIndex <= 0) && (rowData?.installationMasterId === "0" || rowData?.installationMasterId === 0))) {
        return <div className="et-actions">

          { cell.actionButtons.print && cell.actionButtons.print ?
              <button title="Print" onClick={(e) => handleActionClick(e, 'print', data, cell, dataKey)} className="btn text-dark">
                <i className="fas fa-print"></i>
              </button>
              : '' }
          { cell.actionButtons.delete ?
              <Popconfirm title="Είστε σίγουρος οτι θέλετε να το διαγράψετε;" onConfirm={(e) => handleActionClick(e, 'delete', data, cell, dataKey)}>
                <button title="Delete" className="btn text-danger">
                  <i className="fas fa-minus-circle"></i>
                </button>
              </Popconfirm>
              : '' }


          { cell.actionButtons.print ?
              <button title="Export" onClick={(e) => handleActionClick(e, 'export', data, cell, dataKey)} className="btn text-dark">
                <i className="fas fa-upload"></i>
              </button>
              : '' }
        </div>
      } else {
        return <span className="tdelement"></span>;
      }
    }
  }

  let tdWidth = cell.width ? cell.width : 'auto';
  if(cell.field === 'email') {
    tdWidth = 300
  }
  if(tableName === "Company Selection" && cell.field === "vatNumber"){
    tdWidth = "";
  }
  let rowId = ((rowData && rowData._id !== 'undefined')) ? rowData._id : '';
  if (rowId === undefined) {
    rowId = ((rowData && rowData.id !== 'undefined')) ? rowData.id : '';
  }
  return (
      <>
        { !cell.hiddenColumn &&
            <td className={ cell.field } style={{ width: tdWidth }}>
              {
                <CellFormFields
                    quickEditFields = {quickEditFields}
                    tableName={tableName}
                    rowData = {rowData}
                    rowId={rowId}
                    cell = {cell}
                    inputDisabled = {disabled}
                />
              }
            </td>
        }
      </>
  )

}
export default TableBodyCell;

// Get Key by Name
export const getKeyByName = (name, prefix) => {
	let key = '';
	if (name) {
		if (prefix && prefix !=='') {
			key = prefix + name.toLowerCase().trim().replaceAll(' ', '_').replaceAll('-', '_')
		} else {
			key = name.toLowerCase().trim().replaceAll(' ', '_').replaceAll('-', '_')
		}
	}
	return key;
}
// Get Table Columns with localstorage hidden fields
export const getSavedHiddenColumns = (tableName, tableColumns) => {
	if(tableName !== 'Product Balance Report' && tableName !== 'Sales Balance Report' && tableName !== 'Sales Payment Report'  && tableName !== 'Product Movement Report'  && tableName !== 'Client Balance Report') { 
	if (tableName && tableName !=='' && tableColumns) {
		const key = getKeyByName(tableName, 'ethide_');
		let lsColumns = localStorage.getItem(key);
		if (lsColumns && lsColumns !== '') {
			lsColumns = JSON.parse(lsColumns);
			return tableColumns.map(obj => lsColumns.find(o => o.name === obj.name) || obj);
		}
	}
}
	return tableColumns;
}

export const getURLParams = () => {
	const query = new URLSearchParams(window.location.search);
	return query;
  }

  export const getToken = () => {
	let userToken = '';
	const token = localStorage.getItem('token');
	if(token) {
	  userToken = token.replace(/['"]+/g, '');
	} else {
	  console.warn('User token not found.');
	  userToken = false;
	}
	return userToken;
  }
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./components/Login/index.jsx";
import ForgotPassword from "./components/ForgotPassword/index.jsx";
import ForgotUser from "./components/ForgotUser";
import ResetPassword from "./components/ResetPassword";
import EmailSentMessage from "./components/EmailSentMessage";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ResentEmail from "./components/ResentEmail";
import VerifyEmail from "./components/Verify-Email/verify-email";
import Register from "./components/Register";
const App = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  useEffect(() => {
    if (currentUser) {
    } else {

    }
  }, [currentUser]);

  return (
    <React.Fragment>
      <nav className="navbar navbar-expand navbar-dark " style={{backgroundColor: "#F1FDFF", boxShadow: "8px 3px 6px #272d3b33"}}>
        <div
          className="text-center
            navbar-brand-wrapper
            d-flex
            align-items-center
            justify-content-center"
        >
          <Link className="navbar-brand brand-logo logoImg" to="/">
            <img
              src="assets/images/prrimer-logo.png"
              alt="logo MINI"
              className="img-fluid logoForPrint"
            />
          </Link>
        </div>

        {!currentUser ? (
          <div className="navbar-nav ml-auto">

          </div>
        ) : (
          <div className="navbar-nav ml-auto mr-3">
            <li className="nav-item">
              <Link to={"/login"} className="nav-link">
                Login
              </Link>
            </li>
          </div>
        )}
      </nav>

      <div className="container mt-3">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-username" element={<ForgotUser />} />
          <Route path="/reset-password" element={<ResetPassword />}  />
          <Route path="/email-sent" element={<EmailSentMessage/>} />
          <Route path="/resent-email" element={<ResentEmail/>} />
          <Route path="/verify-email" element={<VerifyEmail/>} />


        </Routes>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        />
    </React.Fragment>
  );
};

export default App;

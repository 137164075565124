import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
    FORGOTPASSWORD_FAIL,
    FORGOTPASSWORD_SUCCESS,
    RESETPASSWORD_SUCCESS,
    RESETPASSWORD_FAIL, LICENSEKEY_SUCCESS, LICENSEKEY_FAIL,
} from "./types";

  import AuthService from "../services/auth.service";

  export const login = (email, password, handleShowFooterModal, allowLogout, handleShowFooterModalConfirmation) => (dispatch) => {
    return AuthService.login(email, password,handleShowFooterModal, allowLogout, handleShowFooterModalConfirmation).then(
      (data) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });

        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: LOGIN_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

  export const forgotPasssword = (email, navigate) => (dispatch) => {
    return AuthService.forgotPasssword(email,navigate).then(
      (data) => {
          console.log(data)
        dispatch({
          type: FORGOTPASSWORD_SUCCESS,
          payload: { user: data },
        });

        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: FORGOTPASSWORD_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };
  export const resetPassword = (password, confirmPassword, code,navigate) => (dispatch) => {
    return AuthService.resetPassword(password,confirmPassword, code,navigate).then(
      (data) => {
        dispatch({
          type: RESETPASSWORD_SUCCESS,
          payload: { user: data },
        });

        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: RESETPASSWORD_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

  export const logout = () => (dispatch) => {
    AuthService.logout();

    dispatch({
      type: LOGOUT,
    });
  };

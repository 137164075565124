import React, { useEffect, useState } from "react";
import TableBodyRow from "./TableBodyRow";
import TableInsertDataRow from "./TableInsertDataRow";

const TableBody = (props) => {

  let { data, columns, selectedRow, tableName, allowFilterTable, handleDoubleClick, allowInsertRow, pRequestData, reqName, showErrors } = props;
  const [bodyData, setBodyData] = useState(data);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    setBodyData(data);
  }, [data])


  // This is used on Table Filter Cell
  const filterFieldValue = (value, field) => {
    // Add the value ot new filter
    let filter = { [field]: value }
    let newFilters = {...filters, ...filter};
    setFilters(newFilters);

    // Filter Data
    let filteredData = filterTableBodyData(data, newFilters);
    if (Object.keys(newFilters).length <= 0) {
      setBodyData(data);
    } else {
      setBodyData(filteredData);
    }
  }

  // Delete Object by unique ID or Key
 const deleteObjectFromArrayOfObjects = (data, id, field = 'id') => {
  if (data && data.length > 0 ) {
    return data.filter((item) => item[field] !== id)
  } else {
    return data
  }
}

 const clearEmptyValuesFromObject = (obj) => {
  for (var propName in obj) {
    if (
			obj[propName] === null ||
			obj[propName] === 'null' ||
			obj[propName] === '' ||
			obj[propName] === undefined ||
			(Array.isArray(obj[propName]) && obj[propName].length === 0)
			) {
      delete obj[propName];
    }
  }
  return obj
}


  const filterTableBodyData = (data, filter) => {

    data = deleteObjectFromArrayOfObjects(data, null, '_id') // remove if there is any null value
    filter = clearEmptyValuesFromObject(filter); // clear empty fields

    return data.filter(function(item) {
      let returnType = true;
      for (const key in filter) {
        if (key) {
          let itemVal = item[key]?.toString().toLowerCase().trim();
          let filterVal = filter[key]?.toString().toLowerCase().trim();

          if (key === 'isActive') {
            // Exact Match Case
            if ( item[key] === undefined ||  (itemVal !== filterVal) ) {
              returnType = false;
            }
          } else {
            // Normal Case for input
            if (
              item[key] === undefined ||
              (
                itemVal !== filterVal &&
                // itemVal !== "" && // Fix for empty values on filter
                !itemVal?.includes(filterVal) &&
                !itemVal?.startsWith(filterVal) &&
                !itemVal?.endsWith(filterVal)
              )
            ) {
              returnType = false;
            }
          }
        }
      }
      return returnType;
    });
  }

  const selectRow = (e, data) => {
    // It will select the row on single click
    if ((data._id !== selectedRow) && (e.detail === 1)) {
      props.onRowSelect.getRow(data);
    }
  }
  const handleRowUpdate = (e, data, id) => {
    props.update && props.update(e, data, id);
  }

  const handleRowDoubleClick = (e, row) => {
    if(props?.handleDoubleClick && (e.detail === 2)) {
      props.handleDoubleClick();
    }
  }

  let tableDataLength = data && data?.length ? data?.length : 0;

  return (
    <tbody >

      {/* Table Filter Row */}

      {/* Table Data */}
      {
        bodyData && bodyData.length > 0 && Array.isArray(bodyData)
        ?
        bodyData && bodyData.map((row, rowi) => {
              return <tr onDoubleClick={(e) => handleRowDoubleClick(e, row)} className={selectedRow && row && (row?._id === selectedRow || row?.id === selectedRow) ? 'active-row' : 'inactive-row'} onClick={(e) => selectRow(e, row)} key={rowi} >
                <TableBodyRow rowIndex = {rowi} dataLength = {tableDataLength} tableName = {tableName} updateRowData={handleRowUpdate} showErrors={showErrors} columns={columns} data={row} pRequestData={pRequestData} reqName={reqName}/>
              </tr>
          })
        :
            !allowInsertRow && <tr><td colSpan={columns.length + 1}><span className="tdelement text-center">No records found.</span></td></tr>
      }
      {
        allowInsertRow ? <tr className="insert-new-row">
          <TableInsertDataRow tableName={tableName} {...props} columns={columns}  pRequestData={pRequestData} reqName={reqName}/>
        </tr> : null
      }

    </tbody>
  )

}
export default TableBody;

import React from "react";
import TableBodyCell from "./TableBodyCell";
const TableBodyRow = (props) => {
  let {
    dataLength,
    tableName,
    columns,
    updateRowData,
    rowIndex,
    checkedRows,
    quickEditFields,
    pRequestData,
    reqName,
      showErrors
  } = props;

  let data = Object.assign({}, props.data);
  let dataKey = "";
  if (data) {
    if (!data.id && data._id) {
      dataKey = data["_id"];
    }

    if (!data._id && data.id) {
      dataKey = data["id"];
    }

    if (data._id && data.id) {
      dataKey = data["_id"];
    }
  }

  return (
      <>
        {data &&
            columns &&
            columns.map((cell, celli) => {
              let disabled = false;
              let enableDynamicOptions = false;
              return (
                  <TableBodyCell
                      reqName={reqName}
                      reqData={pRequestData}
                      quickEditFields={quickEditFields}
                      enableDynamicOptions={enableDynamicOptions}
                      isInsertRow={false}
                      tableName={tableName}
                      disabled={disabled}
                      key={celli}
                      columns={columns}
                      rowData={data}
                      showErrors={showErrors}
                      dataKey={dataKey}
                      cell={cell}
                      data={data[cell.field]}
                      updateRowData={updateRowData}
                      dataLength={dataLength}
                      rowIndex={rowIndex}
                      checkedRows={checkedRows}
                  />
              );
            })}
      </>
  );
};
export default TableBodyRow;

import {DoyOptions} from "../_data/doyOptions";

export const getDefaultOptionByValue = (data, id, field = 'id', field1, field2) => {
    let output = {};
    if (data && data.length > 0) {
        const opobj = getObjectFromArrayOfObjects(data, id, field.trim());
        if (opobj.length > 0 && opobj !== 'undefined') {
            output.label = opobj['0'][field1];
            output.value = opobj['0'][field2];
        }
    }
    return output;
}
export const isValidEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const getObjectFromArrayOfObjects = (data, id, field = 'id') => {
    return data.filter((item) => item[field] === id)
}
export const getOptionsByData = (data, field1, field2)  => {
    if (data && data.length > 0) {
        return data.map((item) => ({
            value: item[field1],
            label: item[field2],
            // color: "#76AEB7",
        }));
    } else {
        return [];
    }
}
export const getInstallationsTypes = () => {
    let options = [{
        label: 'Κεντρικό',
        value: "1",
        color: '#76AEB7'
    },
    ];

    for(let oid = 2; oid <= 50 ; oid++) {
        options = [...options, { label: `Υποκατάστημα ${oid - 1}`, value: oid.toString() }]
    }
    return options;
}

export const getDOYOptions = () => {
    let options;
    options = getOptionsByData(DoyOptions,'value', 'label')
    return options;
}
export const getMainInstallation = () => {
    let firstInstalltion = null;
    let allInstallations = getInstallationsTypes();
    if (allInstallations.length > 0) {
        firstInstalltion = allInstallations['0'];
    }

    return firstInstalltion;
}
export const deleteObjectFromArrayOfObjects = (data, id, field = '_id') => {
    if (data && data.length > 0 ) {
        return data.filter((item) => item[field] !== id)
    } else {
        return data
    }
}
export const getKeyByName = (name, prefix) => {
    let key = '';
    if (name) {
        if (prefix && prefix !=='') {
            key = prefix + name.toLowerCase().trim().replaceAll(' ', '_').replaceAll('-', '_')
        } else {
            key = name.toLowerCase().trim().replaceAll(' ', '_').replaceAll('-', '_')
        }
    }
    return key;
}

import React from "react";
import TableBodyCell from "./TableBodyCell";

const TableInsertDataRow = (props) => {
  const { columns, insert, tableName, pRequestData, reqName } = props;

  const handleNewRowEntry = (e, field, defaultValue, ftype) => {

    let value = '';
    if (ftype === 'dd' || ftype === 'ac') {
      value = e.value;
    } else if (ftype === 'db') {
      value = e;
    } else if (ftype === 'tp') {
      value = e;
    } else {
      value = e.target.value;
    }
    const cellData =  {
      field: field,
      value: value,
    }
    insert(e, cellData);
  }

  const handleEmptyFieldEntry = (e) => {
    const cellData =  {
      field: 'add',
      value: 'add',
    }
    e.key = 'Enter'
    insert(e, cellData);
  }

  return (
    <>
    {

      columns && columns.map((oldcell, celli) => {
        let cell =  Object.assign({}, oldcell);
        // Dynamic Options
        let enableDynamicOptions = 'false';
        let data = "";
        let rowData = {};
        let inputPlaceholder = 'Εισαγωγή';
        let disabled = false;

        // Number will be empty for the new field
        if (cell.field === 'no') {
          data = '';
        }

        return (
          cell.field !== 'actions' ?
            <TableBodyCell
              reqName = {reqName}
              reqData={pRequestData}
              enableDynamicOptions = {enableDynamicOptions}
              isInsertRow = {true}
              disabled={disabled}
              placeholder={inputPlaceholder}
              newRow={handleNewRowEntry}
              dataKey={data && data._id ? data._id: '0'}
              key={celli}
              cell={cell}
              data={data}
              rowData = {rowData}
              tableName = {tableName}
            />
          :
            (props.enableNewRowCheckbox && props.enableNewRowCheckbox === true) ?
             <td key={celli} className="text-center w-auto">
               <input
                title="Stops being new row"
                onChange={(e) => handleEmptyFieldEntry(e)}
                type="checkbox"
                checked={false}
                value="addemptyfield"
                />
              </td>
            :
            <td key={celli} className="text-center w-auto"></td>
        )
      })
    }
    </>
  )

}
export default TableInsertDataRow;

import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
const EmailSentMessage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigateToLogin = () => {
    // 👇️ navigate to /contacts

    navigate('/Login');
  };
  return (
    <div className="row align-item-center justify-content-center mt-5">
      <div className="col-md-7">
        <div className="authincation-content">
          <div className="row align-item-center">
            <div className="col-xl-12">
              <div className="login-banner-txt">
              </div>
              <div className="auth-form">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 mx-auto">
                    <a href="/">
                      <img
                        src="assets/images/prrimer-logo.png"
                        className="img-fluid login-logo-center"
                        alt="logo"
                      />
                    </a>
                  </div>
                </div>
                <div className="row">
                  {" "}
                  <text style={{ textAlign: "center", fontSize: "25px" }}>
                    Εάν το email που πληκτρολογήσατε υπάρχει στη βάση δεδομένων μας, θα λάβετε ένα e-mail σύντομα!
                  </text>
                </div>
                <div className="mt-4">
                  <button
                    onClick={navigateToLogin}
                    type="button"
                    className="btn btn-primary btn-block"
                  >
                    Επιστροφή στη Σελίδα Σύνδεσης
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailSentMessage;

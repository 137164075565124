import React from "react";
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import "./editableTable.scss";
import {getSavedHiddenColumns,} from "../../common/commonFunctions";
import {deleteObjectFromArrayOfObjects} from "../../../helpers/commonFunctions";

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.editableTableRef = React.createRef();
    this.state = {
      tableData: this.props.data,
      reqName: this.props.reqName,
      pRequestData: this.props.pRequestData,
      tableName: this.props.tableName,
      filteredData: [],
      checkedRows: [],
      tableColumns: getSavedHiddenColumns(this.props.tableName, this.props.columns),
      count: this.props.data ? this.props.totalItems : 0,
      activePage: 1,
      rowsPerPage: this.props.perPage,
      pageRange: 1,
      requestData: this.props.requestData,
      formData: this.props.formData,
    };
  }
  handleDelete = (id) => {
    const tableData = [...this.state.tableData];
    this.setState({
      tableData: deleteObjectFromArrayOfObjects(tableData, id, "_id"),
    });


    this.setState((state) => {
      return {
        filteredData: deleteObjectFromArrayOfObjects(state.filteredData, id, "_id"),
      };
    });
    this.props.onUpdate && this.props.onUpdate.delete(id);

  };

  componentDidMount = () => {
    // Action Buttons for the Table,
    // Default: Delete button is true
    // This will replace the action buttons with props values.
    let actionButtons = {
      edit: false,
      print: false,
      export: false,
      delete: false,
    };
    if (this.props.actionButtons) {
      actionButtons = this.props.actionButtons;
    }
    // It will add the actions column in the Table
    const actionsColumns = {
      field: "actions",
      name: "",
      width: "100px",
      actionButtons: actionButtons,
      inputType: {
        type: "actions",
        callbackFn: (dataKey, type) => {
          console.log(type)
          switch (type) {
            case "delete":
              this.handleDelete(dataKey);
              break;
            default:
              console.log("nothing to do..");
          }
        },
      },
    };
    if (this.props.allowActions) {
      this.setState({
        tableColumns: [...this.state.tableColumns, actionsColumns],
      });
    }

    this.setState((state, props) => {
      if (state.tableData && state.tableData.length > 0) {
        return {
          filteredData: state.tableData
              ? state.tableData.slice(0, state.rowsPerPage)
              : [],
        };
      }
    });
  };
  static getDerivedStateFromProps =  (props, state) => {
    return {tableData: props.data }
  }

  // Selected Row / Item Id
  getSelectedRow = {
    getRow: (data) => {
      this.props.onUpdate && this.props.onUpdate.selected(data);
    },
  };

  // It will insert a new Row to the Table Data.
  handleInsertNewRowToData = (e, data) => {
    this.props.onUpdate && this.props.onUpdate.insert(e, data);
    const { count, tableData } = this.state;
    if (data.value !== "" && data.field !== "") {
      const newData = {
        id: count + 1,
        [data.field]: data.value,
      };
      this.setState({
        // tableData: [...tableData, newData],
        count: count + 1,
      });
    }
  };
  // Update Row Data
  handleUpdateRowToData = (e, field, id) => {
    let value = "";
    if (e.target !== undefined) {
      value = e.target.value;
    } else if (e.value !== undefined) {
      value = e.value;
    } else {
      value = e;
    }
    if (field !== "") {
      const newData = {[field]: value};
        this.props.onUpdate && this.props.onUpdate.update(newData, id);
    }
  };

  render() {
    const { tableData, filteredData, tableColumns } = this.state;
    // CSV Export
    // It will manage the new insert row with prop allowInsertRow value.
    let tableClasses = "table table-bordered mb-2 editable-table-ui";
    let data = tableData;
    let tableWrClasses = "table-responsive ";
    tableWrClasses = this.props.className ? tableWrClasses + this.props.className : tableWrClasses;
    return (
      <>

        {this.props.heading && (
          <div className="text-muted mb-2">
            <i>{this.props.heading}</i>
          </div>
        )}
        {this.props.showErrors && this.props.showErrorMessage !== "" && (
          <div className="text-danger">{this.props.showErrorMessage}</div>
        )}
        <div
          id="editableTableWr"
          ref={(el) => (this.editableTableRef = el)}
          className={tableWrClasses}
        >
          <table
            style={{ borderCollapse: "collapse" }}
            id="editableTable"
            className={tableClasses}
          >
            <TableHead
              {...this.props}
              toggleColumns={this.handleColumnShowHide}
              saveToggleColumns={this.handleSaveToggleColumns}
              headColumns={tableColumns}
            />

            <TableBody
              {...this.props}
              requestData= {this.state.pRequestData}
              reqName= {this.state.reqName}
              insert={this.handleInsertNewRowToData}
              update={this.handleUpdateRowToData}
              openEditPage={this.openEditPage}
              showErrors={this.props.showErrors}
              editRedirectAllowed={this.props.editRedirectAllowed}
              data={data}
              columns={tableColumns}
              onRowSelect={this.getSelectedRow}
              checkedRows={this.state.checkedRows}
            />
          </table>

        </div>
      </>
    );
  }
}

export default EditableTable;

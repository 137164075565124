import axios from "axios";
import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const ResentEmail = () => {
  const dispatch = useDispatch();
  const apiURL2 = process.env.REACT_APP_API_URL2;
  let params = new URLSearchParams(window.location.search);
  let code =  params.get("code")
    const resendLink = () => {
    axios
    .post(apiURL2 + `/public/resend-code`, { code: code })
    .then((response) => {
      console.log(response);
        if (response.data.success === false) {
          toast.error("Η υπηρεσία δεν είναι διαθέσιμη αυτή τη στιγμή. Παρακαλώ δοκιμάστε ξανά αργότερα.");

      } else {
        toast.success("Ο κωδικός επαλήθευσης αποστέλλεται ξανά");
      }
      })
     .catch((error) => {
      if (error) {
        toast.error("Η υπηρεσία δεν είναι διαθέσιμη αυτή τη στιγμή. Παρακαλώ δοκιμάστε ξανά αργότερα.");
      }
    });
  } 

  return (
    <div className="row align-item-center justify-content-center mt-5">
      <div className="col-md-7">
        <div className="authincation-content">
          <div className="row align-item-center">
            <div className="col-xl-12">
              <div className="login-banner-txt">
              </div>
              <div className="auth-form">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 mx-auto">
                    <a href="/">
                      <img
                        src="assets/images/prrimer-logo.png"
                        className="img-fluid login-logo-center"
                        alt="logo"
                      />
                    </a>
                  </div>
                </div>
                <div className="row">
                  {" "}
                  <text style={{ textAlign: "center", fontSize: "25px", color:'red' }}>
                    Ο κωδικός επαλήθευσης έχει λήξει.
                  </text>
                </div>
                <div className="mt-4" style={{display:'flex', justifyContent:'center'}}>
                  <button
                    onClick={resendLink}
                    type="button"
                    className="btn btn-primary"
                    
                  >
                    Κάντε κλικ εδώ για να στείλετε ξανά νέο.
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResentEmail;
